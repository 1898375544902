import {LitElement, html} from 'lit-element';
import styles from './panoramaTitle.css';

export class PanoramaTitle extends LitElement {
    static get styles() {
        return [styles];
    }

    constructor() {
        super();
    }

    render() {
        return html`<slot></slot>`;
    }
}

customElements.define('yeti-panorama-title', PanoramaTitle);
