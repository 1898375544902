import {LitElement, html} from 'lit-element';
import styles from './documentNavigation.css';
import {DocumentNavigationClickEvent} from './documentNavigationClickEvent.js';

export class DocumentNavigation extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            prevText: {type: String},
            prevLink: {type: String},
            nextText: {type: String},
            nextLink: {type: String}
        }
    }

    constructor() {
        super();
        this.prevText = '';
        this.nextText = '';
        this.prevLink = null;
        this.nextLink = null;
    }

    render() {
        const prevButton = this.prevText ? html`<div class="button button-prev" @click="${this.handlePrevClick}"><div class="vertical-text">${this.prevText}</div></div>` : '';
        const nextButton = html`<div class="button button-next" @click="${this.handleNextClick}"><div class="vertical-text">${this.nextText}</div></div>`;

        return html`
            <div class="navigation">
                ${prevButton}
                ${nextButton}
            </div>
        `;
    }

    handlePrevClick() {
        const event = new DocumentNavigationClickEvent({link: this.prevLink});
        this.dispatchEvent(event);
    }

    handleNextClick() {
        const event = new DocumentNavigationClickEvent({link: this.nextLink});
        this.dispatchEvent(event);
    }
}

customElements.define('yeti-navigation', DocumentNavigation);
