import {html, LitElement} from 'lit-element';
import styles from './lightBox.css';
import {CloseEvent} from './closeEvent.js';

export class LightBox extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            isOpen: {type: Boolean},
            index: {type: Number},
            images: {type: Object}
        }
    }

    constructor() {
        super();
        this.isOpen = false;

        /**
         * @type {LightBoxImage[]}
         */
        this.images = [];

        this.index = 0;
        document.addEventListener('keyup', event => this.handleKeyPress(event));
    }

    render() {
        const currentImage = this.images[this.index];

        const image = currentImage ? this.renderImage(currentImage) : '';

        return html`
            <div class="dialog" ?hidden="${!this.isOpen}">
                <div class="overlay"></div>
                <yeti-close-button @click="${this.handleDocumentClick}" color="#fff"></yeti-close-button>
                <div class="image-container" @click="${this.handleImageClick}">
                    ${image}
                </div>
            </div>
            </div>
        `;
    }

    /**
     * @param {LightBoxImage} image
     */
    renderImage(image) {
        return html`
            <img class="image" src="${image.url}" @loaded="${this.handleLoaded}" alt="${image.title}"/>
            <div class="image-title">${image.title}</div>
        `;
    }

    handleDocumentClick() {
        console.log('ModalDialog.handleDocumentClick', {open: this.isOpen});
        this.close();
    }

    handleImageClick() {
        this.next();
    }

    next() {
        this.index = (this.index + 1) < this.images.length ? (this.index + 1) : 0;
    }

    previous() {
        this.index = (this.index - 1) >= 0 ? (this.index - 1) : 0;
    }

    close() {
        this.dispatchEvent(new CloseEvent());
    }

    handleKeyPress(event) {
        if (this.isOpen) {
            const keyActions = {
                'Space': () => this.next(),
                'Enter': () => this.next(),
                'ArrowRight': () => this.next(),
                'ArrowLeft': () => this.previous(),
                'Escape': () => this.close()
            };

            const action = keyActions[event.code];
            if (action) {
                action();
            }
        }
    }

    handleLoaded() {
        console.log('loaded');
    }
}

customElements.define('yeti-light-box', LightBox);
