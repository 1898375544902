export class SnowFlake {
    /**
     * @param {Partial<SnowFlake>} options
     */
    constructor(options) {
        this.maxWidth = options.maxWidth;
        this.maxHeight = options.maxHeight;

        /**
         * In pixel/second
         * @type {number}
         */
        this.fallSpeed = options.fallSpeed;

        /**
         * In angle/second
         * @type {number}
         */
        this.rotateSpeed = options.rotateSpeed;
        this.rotation = Math.random() * 180;

        this.amplitude = options.amplitude;
        this.startedAt = null;

        this.startX = Math.random() * this.maxWidth;
        this.progress = Math.random();
        this.phase = Math.random() * Math.PI;
        this.scale = this.fallSpeed / 100;
    }

    get x() {
        return this.startX + Math.sin(this.phase + this.progress * 3 * Math.PI) * this.amplitude;
    }

    get y() {
        return -48 + this.progress * this.maxHeight;
    }

    update(timestamp) {
        if (!this.startedAt) {
            this.startedAt = timestamp;
        }
        const elapsed = timestamp - this.startedAt;
        this.startedAt = timestamp;

        const distance = this.fallSpeed * elapsed / 1000;

        this.progress = this.progress + (distance / this.maxHeight);

        if (this.y > this.maxHeight) {
            this.progress = 0;
        }

        const relativeRotation = this.rotateSpeed * elapsed / 1000;
        this.rotation = this.rotation + relativeRotation;
        if (this.rotation <= -360 || this.rotation >= 360) {
            this.rotation = 0;
        }
    }
}
