import {LitElement, html} from 'lit-element';
import styles from './toolbarButton.css';

export class ToolbarButton extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            icon: {type: String}
        }
    }

    constructor() {
        super();
        this.icon = null;
    }

    render() {
        let button;

        if (this.icon) {
            button = html`
                <button class="toolbar-button">
                    <svg viewBox="0 0 24 24">
                        <path fill="#ffffff" d="${this.icon}" />
                    </svg>
                </button>
            `;
        } else {
            button = html`
                <button class="toolbar-button">
                   <slot></slot>
                </button>
            `;
        }

        return button;
    }
}

customElements.define('yeti-toolbar-button', ToolbarButton);
