export class DocumentNavigationClickEvent extends CustomEvent {
    /**
     * @template T
     * @param {T} item
     */
    constructor(item) {
        super('documentNavigationClick', {
            bubbles: true,
            composed: true,
            detail: item
        });
    }
}
