import {css} from 'lit-element';

export default css`:host { display: block; }
:host([hidden]) { display: none }

.grid {
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 2px;
}

.thumbnail {
    display: block;
    position: relative;
    cursor: pointer;
    background-color: #000000;
}

.thumbnail::before {
    content: "";
    padding-bottom: 100%;
    display: block;
}

.thumbnail img {
    position: absolute;
    top: 0;
    left: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
}

.more-images-overlay {
    display: flex;
    justify-content: center;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    color: white;
    background-color: rgba(0, 0, 0, 0.25);
}
.more-images-overlay-count {
    display: inline-block;
    align-self: center;
    font-size: 2rem;
    user-select: none;
    pointer-events: none;
}

`;
  