import {LitElement, html} from 'lit-element';
import styles from './documentSection.css';

export class DocumentSection extends LitElement {
    static get styles() {
        return [styles];
    }

    constructor() {
        super();
    }


    render() {
        return html`<slot>`;
    }
}

customElements.define('yeti-section', DocumentSection);
