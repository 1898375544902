import {html, LitElement} from 'lit-element';
import styles from './profile.css';
import {config} from '../../../config.js';

export class Profile extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            imageUrl: {type: String},
            name: {type: String},
            title: {type: String},
            mail: {type: String}
        }
    }

    constructor() {
        super();
        this.imageUrl = null;
        this.name = '';
        this.title = '';
        this.mail = '';
    }

    render() {
        const titleElement = this.title ? html`<p class="title">${this.title}</p>` : '';

        return html`<div class="profile">
            <div class="media">
                <img class="profile-image" src="${config.imageBaseUrl}${this.imageUrl}" alt="${this.name}"/>
            </div>
            <div class="content">
                <h2 class="name">${this.name}</h2>
                ${titleElement}
                <p class="mail">${this.mail}</p>
            </div>
        </div>`;
    }
}


customElements.define('yeti-profile', Profile);
