import {LitElement, html} from 'lit-element';
import styles from './imageSection.css';
import {config} from '../../../config.js';
import {PreviewImageClickEvent} from './previewImageClickEvent.js';

export class ImageSection extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            jsonUrl: {type: String},
            maxNumberOfPreviewImages: {type: Number},
        }
    }

    constructor() {
        super();
        this.jsonUrl = null;
        this.content = null;
        this.maxNumberOfPreviewImages = 4;
    }

    async performUpdate() {
        if (this.jsonUrl) {
            const response = await fetch(this.jsonUrl);
            this.content = await response.json();
            console.log(this.content);
        }

        super.performUpdate();
    }

    render() {
        let items = [];

        if (this.content) {
            const allImages = this.content.images;
            const previewImages = allImages.slice(0, this.maxNumberOfPreviewImages);

            items = previewImages.map((image, index) => {
                let overlayElement = null;
                if (index === this.maxNumberOfPreviewImages - 1 && allImages.length > this.maxNumberOfPreviewImages) {
                    overlayElement = html`<div class="more-images-overlay"><div class="more-images-overlay-count">+${allImages.length - this.maxNumberOfPreviewImages}</div></div>`
                }

                const imageUrl208 = `${config.imageBaseUrl}/w/208/${image.url}`

                return html`
                    <div class="thumbnail" @click="${this.handleImageClick}" data-index="${index}">
                        <img width="" src="${imageUrl208}" alt="${image.title}" />
                        ${overlayElement}
                    </div>
                `;
            });
        }

        return html`
            <div>
                <slot></slot>
                <div class="grid">
                    ${items}
                </div>
            </div>
        `;
    }

    handleImageClick(event) {
        const index = parseInt(event.currentTarget.getAttribute('data-index'), 10);

        const previewImageClickEvent = new PreviewImageClickEvent({
            images: this.content.images.map(this.convertImage),
            index
        });
        this.dispatchEvent(previewImageClickEvent);
    }

    convertImage(image) {
        return {...image, url: `${config.imageBaseUrl}/w/2048/${image.url}`};
    }
}

customElements.define('yeti-image-section', ImageSection);
