import {LitElement, html} from 'lit-element';
import styles from './closeButton.css';
import {mdiClose} from '@mdi/js';
import {CloseClickEvent} from './closeClickEvent.js';

export class CloseButton extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            color: {type: String}
        }
    }

    constructor() {
        super();
        this.color = '#000000';
    }

    render() {

        return html`
            <div class="close-button" @click="${this.handleClickEvent}">
                <svg viewBox="0 0 24 24">
                    <path fill="${this.color}" d="${mdiClose}" />
                </svg>
            </div>`;
    }

    handleClickEvent() {
        this.dispatchEvent(new CloseClickEvent());
    }
}

customElements.define('yeti-close-button', CloseButton);
