import {LitElement, html} from 'lit-element';
import styles from './mapMarker.css';
import mapMarkerIcon from '../../../icons/mapMarkerCircle.svg';
import mapMarkerActiveIcon from '../../../icons/mapMarkerCircleActive.svg';

export class MapMarker extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            isActive: {type: Boolean}
        }
    }

    constructor() {
        super();
        this.isActive = false;
    }

    render() {
        return this.isActive ? html`${mapMarkerActiveIcon}` : html`${mapMarkerIcon}`;
    }
}

customElements.define('yeti-map-marker', MapMarker);
