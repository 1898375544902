import {css} from 'lit-element';

export default css`:host,
pan-map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.map {
    position: absolute;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}
`;
  