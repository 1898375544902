import {css} from 'lit-element';

export default css`
:host,
 map-marker {
    position: relative;
    display: block;
    width: 40px;
    height: 40px;
    cursor: pointer;
}

.icon {
    position: absolute;
    width: 40px;
    height: 40px;
}
`;
  