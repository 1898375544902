import {LitElement, html} from 'lit-element';
import styles from './documentPictureStyles.css';
import {config} from '../../../config.js';

export class DocumentPicture extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            src: {type: String},
            contribution: {type: String}
        }
    }

    constructor() {
        super();
        this.src = null;
        this.contribution = null;
    }

    render() {
        let contributionElement = null;

        if (this.contribution) {
            contributionElement = html`<div class="image-contribution">${this.contribution}</div>`;
        }

        const imageUrl = `${config.imageBaseUrl}${this.src}`;

        return html`
            <div class="image-container">
                <div class="image-background"></div>
                <img class="image" src="${imageUrl}" alt="" />
                ${contributionElement}
            </div>
        `;
    }
}

customElements.define('yeti-picture', DocumentPicture);
