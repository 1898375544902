import {LitElement, html} from 'lit-element';
import styles from './toolbar.css';

export class Toolbar extends LitElement {
    static get styles() {
        return [styles];
    }

    constructor() {
        super();
    }

    render() {
        return html`
            <div class="toolbar">
                <slot></slot>
            </div>
        `;
    }
}

customElements.define('yeti-toolbar', Toolbar);
