import {css} from 'lit-element';

export default css`:host {
    scroll-behavior: smooth;
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    background-color: white;
}

.content-panel {
    background-color: white;
}

h1 {
    font-size: 1.375rem;
    line-height: 1.75rem;
    text-transform: uppercase;
}

h2 {
    font-size: 1rem;
    line-height: 1.75rem;
    color: rgba(0, 0, 0, 0.8);
}

header {
    padding: 1rem;
    border-bottom: 1px solid #c0c0c0;
}

p {
    line-height: 1.5rem;
}
`;
  