import {css} from 'lit-element';

export default css`:host {
    --width: var(--sidebar-panel-width, 450px);
    --background-color: var(--sidebar-panel-background-color, #000000);
}

.sidebar {
    position: absolute;
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    overflow: hidden;
}

.sidebar-aside {
    position: absolute;
    left: 0;
    top: 0;
    bottom: 0;
    width: 0;
    opacity: 0;
    background-color: var(--background-color);
    overflow: auto;
    box-shadow: 0 0 20px rgba(0, 0, 0, 0.5);
    z-index: 10;
}

.sidebar-aside-content {
    width: min(var(--width), 100vw);
}

.sidebar[data-is-expanded] .sidebar-aside {
    width: auto;
    opacity: 1;
}

.sidebar-body {
    position: absolute;
    left: 0;
    top: 0;
    right: 0;
    bottom: 0;
    z-index: 1;
}

.sidebar[data-is-expanded] .sidebar-body {
    left: var(--width);
}

`;
  