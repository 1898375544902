import {css} from 'lit-element';

export default css`:host {
    position: relative;
    display: inline-block;
    color: #a9c1df;

    font-size: 2rem;
    font-family: "Roboto Slab", serif;
    font-style: italic;
    letter-spacing: 0.25rem;
    word-spacing: 1rem;
    text-align: center;
}

@media (max-width: 450px) {
    :host {
        font-size: 1.5rem;
    }
}
`;
  