import {LitElement, html} from 'lit-element';
import styles from './spotLabel.css';

export class SpotLabel extends LitElement {
    static get styles() {
        return [styles];
    }

    constructor() {
        super();
    }

    render() {
        return html`
            <div class="text"><slot></slot></div>
            <div class="line"></div>
        `;
    }
}

customElements.define('yeti-panorama-spot-label', SpotLabel);
