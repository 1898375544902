import {LitElement, html} from 'lit-element';
import styles from './contentPanelStyles.css';
import {unsafeHTML} from 'lit-html/directives/unsafe-html.js';

import './closeButton/closeButton.js';
import './documentSection/documentSection.js';
import './documentNavigation/documentNavigation.js';
import './documentPicture/documentPicture.js';
import './factSection/factSection.js';
import './imageSection/imageSection.js';
import './profile/profile.js';

export class ContentPanel extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            url: {type: String}
        }
    }

    constructor() {
        super();
        this.url = null;
        this.content = '';
    }

    async performUpdate() {
        const response = await fetch(this.url);
        this.content = await response.text();

        super.performUpdate();
    }

    render() {
        let content = html`<slot>`;

        if (this.content) {
            content = html`${unsafeHTML(this.content)}`;
        }

        return html`<div class="content-panel">${content}</div>`;
    }
}

customElements.define('yeti-content-panel', ContentPanel);
