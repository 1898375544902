import {css} from 'lit-element';

export default css`:host {
    --camera-icon: url("data:image/svg+xml,%3C%3Fxml version='1.0' encoding='UTF-8' %3F%3E%3C!DOCTYPE svg PUBLIC '-//W3C//DTD SVG 1.1//EN' 'http://www.w3.org/Graphics/SVG/1.1/DTD/svg11.dtd'%3E%3Csvg xmlns='http://www.w3.org/2000/svg' version='1.1' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M4,4H7L9,2H15L17,4H20A2,2 0 0,1 22,6V18A2,2 0 0,1 20,20H4A2,2 0 0,1 2,18V6A2,2 0 0,1 4,4M12,7A5,5 0 0,0 7,12A5,5 0 0,0 12,17A5,5 0 0,0 17,12A5,5 0 0,0 12,7M12,9A3,3 0 0,1 15,12A3,3 0 0,1 12,15A3,3 0 0,1 9,12A3,3 0 0,1 12,9Z' /%3E%3C/svg%3E");
}

.image-container {
    position: relative;
    width: 100%;
    height: 300px;
}

.image-background {
    position: absolute;
    width: 100%;
    height: 100%;
    background-repeat: no-repeat;
    background-position: center center;
    background-size: 24px 24px;
    background-color: #f0f0f0;
    background-image: var(--camera-icon);
}

.image {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
}

.image-contribution {
    position: absolute;
    right: 0;
    bottom: 0;
    padding-left: 0.5rem;
    padding-right: 2rem;
    text-align: right;
    color: white;
    font-size: 0.875rem;
    line-height: 1.5rem;
    height: 1.5rem;

    background-repeat: no-repeat;
    background-position: right 0.5rem center;
    background-size: 16px 16px;
    background-color: rgba(0, 0, 0, 0.25);
    background-image: var(--camera-icon);
}
`;
  