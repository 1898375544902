import {LitElement, html} from 'lit-element';
import {mdiInformationOutline, mdiMapMarker, mdiMapMarkerOutline, mdiTag, mdiTagOutline, mdiSnowflake, mdiSnowflakeAlert} from '@mdi/js';
import styles from './app.css';
import {peaks} from '../../public/content/peaks.js';
import {markers} from '../../public/content/markers.js';
import {OverlayType} from './panoramaMap/overlayType.js';

import './contentPanel/contentPanel.js';
import './panoramaMap/panoramaMap.js';
import './toolbar/toolbar.js';
import './toolbar/toolbarButton.js';
import './snowFall/snowFall.js';
import './sidebarPanel/sidebarPanel.js';
import './lightBox/lightBox.js';

export class App extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            sidebarUrl: {type: String},
            isSidebarExpanded: {type: Boolean},
            isMarkersLayerVisible: {type: Boolean},
            isPeakLabelsVisible: {type: Boolean},
            isSnowActive: {type: Boolean},
            isLightBoxOpen: {type: Boolean},
            lightBoxImages: {type: Array},
            lightBoxImageIndex: {type: Number},
        }
    }

    constructor() {
        super();
        this.sidebarUrl = '';
        this.isMarkersLayerVisible = true;
        this.isLabelsLayerVisible = true;
        this.isSidebarExpanded = false;
        this.isSnowActive = false;
        this.isLightBoxOpen = false;
        this.lightBoxImages = [];
        this.lightBoxImageIndex = 0;
    }

    firstUpdated() {
        super.connectedCallback();

        if (screen && screen.orientation) {
            screen.orientation.lock('landscape')
                .then(() => console.log('Use landscape orientation'))
                .catch(error => console.log(error));
        }
    }

    /**
     * @param {Map<string, any>} changedProperties
     */
    update(changedProperties) {
        super.update(changedProperties);

        if (changedProperties.has('sidebarUrl') || changedProperties.has('isSidebarExpanded')) {
            if (this.isSidebarExpanded) {
                const activeMarker = markers.find(item => item.markdown === this.sidebarUrl);
                this.map.setActiveMarker(activeMarker);
            } else {
                this.map.setActiveMarker(null);
            }
        }
    }

    updated(changedProperties) {
        super.updated(changedProperties);

        if (changedProperties.has('sidebarUrl') ) {
            if (this.sidebarUrl.includes('#snow')) {
                console.log('❄ Activate snow, because the sidebar URL contains "#snow". 😄');
                this.isSnowActive = true;
            } else {
                this.isSnowActive = false;
            }
        }
    }

    render() {
        let sidebarElement = '';

        if (this.sidebarUrl) {
            sidebarElement = html`
            <yeti-content-panel 
                slot="sidebar" 
                .url="${this.sidebarUrl}"
                @documentCloseClick="${this.handleDocumentCloseClick}"
                @documentNavigationClick="${this.handleDocumentNavigationClick}"
                @previewImageClick="${this.handlePreviewImageClick}"
                >
            </yeti-content-panel>`;
        }

        const toggleMarkerButtonIcon = this.isMarkersLayerVisible ? mdiMapMarker : mdiMapMarkerOutline;
        const toggleLabelButtonIcon = this.isLabelsLayerVisible ? mdiTag : mdiTagOutline;
        const toggleSnowButtonIcon = this.isSnowActive ? mdiSnowflakeAlert : mdiSnowflake;

        return html`
            <yeti-sidebar-panel class="sidebar" .isExpanded="${this.isSidebarExpanded}">
                <yeti-panorama-map 
                    slot="body" 
                    .peaks="${peaks}" 
                    .markers="${markers}"
                    title="Mera Peak Panorama"
                    @mapClick="${this.handleMapClickEvent}"
                    @mapMarkerClick="${this.handleMapMarkerClickEvent}"
                    @mapStart="${this.handleMapStartEvent}"
                >
                </yeti-panorama-map>
                <yeti-toolbar slot="body">
                    <yeti-toolbar-button .icon="${mdiInformationOutline}" @click="${this.showInfo}"></yeti-toolbar-button>
                    <yeti-toolbar-button .icon="${toggleLabelButtonIcon}" @click="${this.toggleLabels}"></yeti-toolbar-button>
                    <yeti-toolbar-button .icon="${toggleMarkerButtonIcon}" @click="${this.toggleMarkers}"></yeti-toolbar-button>
                    <yeti-toolbar-button .icon="${toggleSnowButtonIcon}" @click="${this.toggleSnowFall}"></yeti-toolbar-button>
                </yeti-toolbar>
                ${sidebarElement}
            </yeti-sidebar-panel>
            <yeti-snow-fall .isActive="${this.isSnowActive}"></yeti-snow-fall>
            <yeti-light-box 
                .isOpen="${this.isLightBoxOpen}" 
                .images="${this.lightBoxImages}"
                .index="${this.lightBoxImageIndex}"
                @close="${() => this.isLightBoxOpen = false}">
            </yeti-light-box>
        `;
    }

    zoomIn() {
        console.log('Zoom in');
        const mapView = this.map.getView();
        const currentZoom = mapView.getZoom();
        const newZoom = currentZoom + 0.25;
        console.log('Change zoom', {currentZoom, newZoom});

        mapView.setZoom(newZoom);
    }

    zoomOut() {
        console.log('Zoom out');
        const mapView = this.map.getView();
        const currentZoom = mapView.getZoom();
        const newZoom = currentZoom - 0.25;
        console.log('Change zoom', {currentZoom, newZoom});

        mapView.setZoom(newZoom);
    }

    showInfo() {
        console.log('Show info.');
        this.isSidebarExpanded = !this.isSidebarExpanded;
        this.sidebarUrl = this.isSidebarExpanded ? '/content/about/about.html' : null;
    }

    toggleMarkers() {
        this.isMarkersLayerVisible = !this.isMarkersLayerVisible;

        if (this.isMarkersLayerVisible) {
            this.map.showOverlays(OverlayType.markers);
        } else {
            this.map.hideOverlays(OverlayType.markers);
            this.map.hideOverlays(OverlayType.start);
        }
    }

    toggleLabels() {
        this.isLabelsLayerVisible = !this.isLabelsLayerVisible;

        if (this.isLabelsLayerVisible) {
            this.map.showOverlays(OverlayType.title);
            this.map.showOverlays(OverlayType.peaks);
        } else {
            this.map.hideOverlays(OverlayType.title);
            this.map.hideOverlays(OverlayType.peaks);
        }
    }

    toggleSnowFall() {
        this.isSnowActive = !this.isSnowActive;
    }

    /**
     * @param {MapClickEvent} event
     */
    handleMapClickEvent(event) {
        console.log(event);
    }

    /**
     * @param {MapMarkerClickEvent} event
     */
    handleMapMarkerClickEvent(event) {
        console.log(event);
        const marker = event.detail;

        if (marker && marker.markdown) {
            this.isSidebarExpanded = true;
            this.sidebarUrl = marker.markdown;
        }
    }

    handleMapStartEvent() {
        this.map.hideOverlays(OverlayType.start);
        this.isSidebarExpanded = true;
        this.sidebarUrl = this.isSidebarExpanded ? '/content/about/about.html' : null;
    }

    /**
     * @param {MapMarkerClickEvent} event
     */
    handleDocumentCloseClick(event) {
        console.log(event);
        this.isSidebarExpanded = false;
        this.sidebarUrl = '';
    }

    /**
     * @param {DocumentNavigationClickEvent} event
     */
    handleDocumentNavigationClick(event) {
        const link = event.detail.link;

        if (link) {
            this.isSidebarExpanded = true;
            this.sidebarUrl = link;
        }
    }

    /**
     * @param {PreviewImageClickEvent} event
     */
    handlePreviewImageClick(event) {
        console.log('Image clicked, open lightbox...', {detail: event.detail});

        this.lightBoxImages = event.detail.images;
        this.lightBoxImageIndex = event.detail.index;
        this.isLightBoxOpen = true;
    }

    /**
     * @returns {PanoramaMap}
     */
    get map() {
        return /** @type {PanoramaMap} */this.shadowRoot.querySelector('yeti-panorama-map');
    }
}

customElements.define('yeti-app', App);
