import {css} from 'lit-element';

export default css`:host,
 pan-spot-label {
    position: relative;
    display: block;
}

.line {
    position: absolute;
    border-left: solid 1px white;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
}

.text {
    font-size: 0.8rem;
    letter-spacing: 1px;
    color: white;
    position: relative;
    top: -0.125rem;
    padding-left: 0.25rem;
}
`;
  