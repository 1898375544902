import {css} from 'lit-element';

export default css`:host {
    display: block;
    position: absolute;
    top: 1.5rem;
    left: 1.5rem;
    width: 2rem;
    min-height: 2rem;
    background-color: rgba(255, 255, 255, 0.5);
    border: solid 4px rgba(255, 255, 255, 0.5);
    border-radius: 2px;
}
`;
  