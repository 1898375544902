import {LitElement, html} from 'lit-element';
import styles from './snowFall.css';
import {SnowFallOptions} from './snowFallOptions';
import {SnowFlake} from './snowFlake';

export class SnowFall extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            isActive: {type: Boolean},
            options: {type: Object}
        }
    }

    set isActive(value) {
        const oldValue = this._isActive;
        this._isActive = value;

        if (this.isActive) {
            this.createSnowFlakes();
            requestAnimationFrame(timestamp => this.updateAnimation.call(this, timestamp));
        } else {
            this.snowFlakes = [];
        }

        this.requestUpdate('isActive', oldValue);
    }

    get isActive() {
        return this._isActive;
    }

    constructor() {
        super();
        this._isActive = false;
        this.options = new SnowFallOptions();
        this.snowFlakes = [];

        window.addEventListener('resize', () => {
            console.log('RESIZE');
            if (this.isActive) {
                this.createSnowFlakes();
            }
        });
    }

    createSnowFlakes() {
        const maxWidth = window.innerWidth;
        this.snowFlakes = [];
        for(let i = 0; i < Math.abs(maxWidth / 10); i++) {
            this.snowFlakes.push(new SnowFlake({
                maxWidth: window.innerWidth,
                maxHeight: window.innerHeight,
                fallSpeed: 50 + Math.random() * 100,
                rotateSpeed: -45 + Math.random() * 90,
                amplitude: 10 + Math.random() * (maxWidth/10)
            }));
        }
        console.log('Snowflakes created', {count: this.snowFlakes.length});
    }

    updateAnimation(timestamp) {
        this.snowFlakes.forEach(flake => flake.update(timestamp));
        this.update();

        if (this.isActive) {
            requestAnimationFrame(timestamp => this.updateAnimation.call(this, timestamp));
        } else {
            this.snowFlakes = [];
        }
    }

    render() {
       return html`${this.snowFlakes.map(flake => html`<div class="snowflake" style="transform: translate3d(${flake.x}px, ${flake.y}px, 0) rotate(${flake.rotation}deg) scale(${flake.scale});"></div>`)}`;
    }

    firstUpdated() {
    }
}

customElements.define('yeti-snow-fall', SnowFall);
