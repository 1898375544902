import {css} from 'lit-element';

export default css`:host {
    display: block;
}

.fact-item {
    display: flex;
    height: 40px;
}

.fact-item-icon {
    width: 24px;
    height: 24px;
    align-self: center;
}

.fact-item-text {
    line-height: 40px;
    padding-left: 2rem;
    color: var(--font-color-grey, #606060);
}
`;
  