export class MapStartEvent extends CustomEvent {
    /**
     * @template T
     * @param {T | null | undefined} [item]
     */
    constructor(item) {
        super('mapStart', {
            bubbles: true,
            composed: true,
            detail: item || null
        });
    }
}
