import {css} from 'lit-element';

export default css`:host {
}
.profile {
    display: flex;
    margin: 1rem 0;
}

.media {
    display: block;
    width: 64px;
    height: 64px;
    border-radius: 50%;
    overflow: hidden;
}

.content {
    margin-left: 2rem;
    display: flex;
    flex-direction: column;
    justify-content: center;
}

h2.name {
    font-size: 1.0rem;
    font-family: "Roboto Slab", serif;
    margin: 0;
    padding: 0;
    font-weight: bold;
}

p {
    margin: 0.25rem 0 0;
}

p.title {
    margin: 0.25rem 0 0;
    font-size: 0.8rem;
    font-style: italic;
}

p.mail {
    font-size: 0.8rem;
}

.profile-image {
    width: 100%;
    height: 100%;
    object-fit: cover;
}
`;
  