export const peaks = [
    {
        label: 'Everest 8848m',
        position: [4810, 19540],
        padding: 40,
        markdown: '/content/everest.md'
    },
    {
        label: 'Lhotse 8516m',
        position: [5326, 19455],
        padding: 30
    },
    {
        label: 'Peak 41 6623m',
        position: [8370, 18849],
        padding: 30
    },
    {
        label: 'Chamlang 7321m',
        position: [14198, 19257],
        padding: 30
    },
    {
        label: 'Makalu 8485m',
        position: [12525, 19294],
        padding: 45
    },
    {
        label: ' Kangchenjunga 8586m',
        position: [21662, 18440],
        padding: 30
    },
];
