import {css} from 'lit-element';

export default css`:host {
    --prev-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M20,10V14H11L14.5,17.5L12.08,19.92L4.16,12L12.08,4.08L14.5,6.5L11,10H20Z' /%3E%3C/svg%3E");
    --next-icon: url("data:image/svg+xml,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 24 24'%3E%3Cpath fill='%23ffffff' d='M4,10V14H13L9.5,17.5L11.92,19.92L19.84,12L11.92,4.08L9.5,6.5L13,10H4Z' /%3E%3C/svg%3E");
    display: block;
}

.navigation {
    display: flex;
    height: 3rem;
    color: white;
    line-height: 1.25rem;
}

.navigation > * {
    margin: 0 0.5rem;
}

.button {
    display: flex;
    flex: 1 1;
    height: 3rem;
    justify-content: center;
    align-items: center;
    background-repeat: no-repeat;
    background-size: 24px 24px;
    cursor: pointer;
}

.button:hover {
    opacity: 0.9;
}

.button-prev {
    background-color: #506B7A;
    padding-left: 2rem;
    padding-right: 0.5rem;
    background-position: 0.5rem center;
    background-image: var(--prev-icon);
}

.button-next {
    background-color: #1E88E5;
    padding-left: 0.5rem;
    padding-right: 2rem;
    background-position: calc(100% - 0.5rem) center;
    background-image: var(--next-icon);
}

.vertical-text {
    display: inline-block;
    flex: 0 1 auto;
}
`;
  