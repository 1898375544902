import {css} from 'lit-element';

export default css`
    :host {
        display: block;
    }

    .toolbar-button {
        display: block;
        outline: 0;
        border: none;
        background: rgba(0, 60, 136, 0.5);
        margin: 0;
        padding: 4px;
        height: 32px;
        width: 32px;
        cursor: pointer;
    }

    .toolbar-button:hover {
        background: rgba(0, 60, 136, 0.65);
    }

    .toolbar-button > svg {
        display: block;
        height: 24px;
        width: 24px;
        margin: 0;
    }
`;
  