import {LitElement, html} from 'lit-element';
import styles from './sidebarPanel.css';

export class SidebarPanel extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            isExpanded: {type: Boolean}
        }
    }

    constructor() {
        super();
        this.isExpanded = false;
    }

    render() {
        return html`
            <div class="sidebar" ?data-is-expanded="${this.isExpanded}">
                <aside class="sidebar-aside" >
                    <div class="sidebar-aside-content">
                        <slot name="sidebar"></slot>
                    </div>
                </aside>
                <div class="sidebar-body">
                    <slot name="body"></slot>
                </div>
            </div>
        `;
    }
}

customElements.define('yeti-sidebar-panel', SidebarPanel);
