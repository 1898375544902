import {css} from 'lit-element';

export default css`:host {
    position: absolute;
    z-index: 1000;
}

.dialog {
    display: block;
    position: fixed;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    user-select: none;
}

.dialog[hidden] {
    display: none;
}

.overlay {
    position: absolute;
    width: 100vw;
    height: 100vh;
    opacity: 0.8;
    background-color: black;
}

.image-container {
    position: absolute;
    top: 5%;
    right: 5%;
    bottom: 5%;
    left: 5%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
}

.image {
    max-width:90vw;
    max-height:90vh;
    width: auto;
    height: auto;
    /*width: 100%;*/
    /*height: 100%;*/
    /*object-fit: contain;*/
    border: solid 1px white;
    cursor: pointer;
    user-select: none;
}

.image-title {
    color: white;
    text-align: center;
    line-height: 3rem;
}
`;
  