import {css} from 'lit-element';

export default css`.close-button {
    width: 24px;
    height: 24px;
    position: absolute;
    top: 1rem;
    right: 1rem;
    cursor: pointer;
    z-index: 1000;
    border-radius: 50%;
    background-color: transparent;
    box-sizing: content-box;
    border: 3px solid transparent;
}

.close-button:hover path {
    fill: white;
}

.close-button:hover {
    border: 3px solid white;
}

`;
  