import {LitElement, html} from 'lit-element';
import styles from './factSection.css';
import {mdiThermometerLow, mdiAltimeter, mdiClockOutline} from '@mdi/js';

export class FactSection extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            height: {type: String},
            time: {type: String},
            temprature: {type: String}
        }
    }

    constructor() {
        super();
        this.height = null;
        this.time = null;
        this.temprature = null;
    }

    render() {
        let heightElement = null;
        let timeElement = null;
        let temperatureElement = null;

        if (this.height) {
            heightElement = this.createFactItemElement({
                icon: mdiAltimeter,
                text: this.height,
                title: 'Altitude'
            });
        }

        if (this.time) {
            timeElement = this.createFactItemElement({
                icon: mdiClockOutline,
                text: this.time,
                title: 'Time / Uhrzeit'
            });
        }

        if (this.temprature) {
            temperatureElement = this.createFactItemElement({
                icon: mdiThermometerLow,
                text: this.temprature,
                title: 'Temperature / Temperatur'
            });
        }

        return html`
            ${heightElement}
            ${timeElement}
            ${temperatureElement}
        `;
    }

    createFactItemElement(item) {
        return html`
            <div class="fact-item" title="${item.title}">
                <div class="fact-item-icon">
                <svg viewBox="0 0 24 24">
                    <path fill="#1e88e5" d="${item.icon}" />
                </svg>
                </div>
                <div class="fact-item-text">${item.text}</div>
            </div>`;
    }
}

customElements.define('yeti-facts', FactSection);
