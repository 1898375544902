import {LitElement, html} from 'lit-element';
import styles from './panoramaMap.css';
import Map from 'ol/Map';
import View from 'ol/View';
import TileLayer from 'ol/layer/Tile';
import XYZ from 'ol/source/XYZ';
import TileGrid from 'ol/tilegrid/TileGrid.js';
import Projection from 'ol/proj/Projection.js';
import Overlay from "ol/Overlay";
import * as interaction from 'ol/interaction.js';
import {OverlayType} from './overlayType.js';
import {MapClickEvent} from './mapClickEvent.js';
import {MapMarkerClickEvent} from './mapMarkerClickEvent.js';
import {MapStartEvent} from './mapStartEvent.js';

import './mapMarker/mapMarker.js';
import './panoramaTitle/panoramaTitle.js';
import './spotLabel/spotLabel.js';
import './startButton/startButton.js';

export class PanoramaMap extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
            title: {type: String},
            peaks: {type: Array},
            markers: {type: Array}
        }
    }

    constructor() {
        super();
        this.peaks = [];
        this.markers = [];
        this.map = null;
        this.title = '';
        this.extent = [0, 0, 32768, 32768];
        this.overlays = {
            [OverlayType.title]: [],
            [OverlayType.peaks]: [],
            [OverlayType.markers]: [],
            [OverlayType.start]: []
        };
        this.activeMarker = null;
    }

    render() {
        return html`
            <link rel="stylesheet" href="./ol.css">
            <div id="map" class="map"></div>
        `;
    }

    firstUpdated() {
        super.connectedCallback();

        const mapContainer = this.shadowRoot.getElementById('map');
        this.initializeMap(mapContainer);

        this.initializeTitleOverlay();
        this.initializeStartOverlay();
        this.initializePeaksOverlay();
        this.initializeMarkersOverlay();

        this.showOverlays(OverlayType.title);
        this.showOverlays(OverlayType.peaks);
        this.showOverlays(OverlayType.markers);
        this.showOverlays(OverlayType.start);

        this.map.on('click', event => {
            const mapClickEvent = new MapClickEvent({
                coordinate: event.coordinate.map(x => Math.abs(x))
            })
            this.dispatchEvent(mapClickEvent);
        });

        const resizeObserver = new ResizeObserver(() => {
            this.map.updateSize();
        });

        resizeObserver.observe(mapContainer);
    }

    /**
     * @param {OverlayType} type
     */
    showOverlays(type) {
        if (this.map) {
            this.overlays[type].forEach(overlay => this.map.addOverlay(overlay));
        }
    }

    /**
     * @param {OverlayType} type
     */
    hideOverlays(type) {
        this.overlays[type].forEach(overlay => this.map.removeOverlay(overlay));
    }

    /**
     * @param {!HTMLElement} hostElement
     */
    initializeMap(hostElement) {
        const tilegrid = new TileGrid({
            extent: this.extent,
            resolutions: [128, 64, 32, 16, 8, 4, 2, 1]
        });

        const projection = new Projection({
            code: 'pixels',
            units: 'pixels',
            extent: this.extent
        });

        this.map = new Map({
            controls: [],
            target: hostElement,
            layers: [
                new TileLayer({
                    source: new XYZ({
                        url: 'https://zeitport.fra1.cdn.digitaloceanspaces.com/merapeak/tiles/{z}/{x}/{y}.png',
                        maxZoom: 7,
                        minZoom: 0,
                        wrapX: false,
                        projection: projection,
                        attributions: `© 2015 - 2020 Christian Schuller`
                    })
                })
            ],
            view: new View({
                center: [16384, 16384],
                zoom: 1,
                maxZoom: 7,
                projection: projection,
                extent: this.extent,
                resolutions: tilegrid.getResolutions()
            }),
            interactions: interaction.defaults({altShiftDragRotate: false, pinchRotate: false})
        });
    }

    initializeTitleOverlay() {
        const titleElement = document.createElement('yeti-panorama-title');
        titleElement.innerText = this.title;

        const titleOverlay = new Overlay({
            position: [18421, 21000],
            positioning: 'center-center',
            element: titleElement,
            stopEvent: false
        });

        this.overlays[OverlayType.title] = [titleOverlay];
    }

    initializeStartOverlay() {
        const startButtonElement = document.createElement('yeti-start-button');
        startButtonElement.innerText = 'Start';

        startButtonElement.addEventListener('click', () => {
            this.dispatchEvent(new MapStartEvent());
        });

        const startOverlay = new Overlay({
            position: [18421, 12100],
            positioning: 'center-center',
            element: startButtonElement,
            stopEvent: false
        });

        this.overlays[OverlayType.start] = [startOverlay];
    }

    initializePeaksOverlay() {
        this.overlays[OverlayType.peaks] = this.peaks.map(peak => {
            const spotLabelElement = document.createElement('yeti-panorama-spot-label');
            spotLabelElement.innerText = peak.label;
            const height = peak.padding || 30;
            spotLabelElement.setAttribute('style', `height:${height}px;`);

            return new Overlay({
                position: peak.position,
                positioning: 'bottom-left',
                element: spotLabelElement,
                stopEvent: false
            });
        });
    }

    initializeMarkersOverlay() {
        this.overlays[OverlayType.markers].forEach(overlay => this.map.removeOverlay(overlay));

        this.overlays[OverlayType.markers] = this.markers.map(marker => {
            const element = document.createElement('yeti-map-marker');
            element.isActive = (marker === this.activeMarker);

            element.addEventListener('click', () => {
                this.dispatchEvent(new MapMarkerClickEvent(marker));
            });

            return new Overlay({
                position: marker.position,
                positioning: 'bottom-center',
                element: element,
                stopEvent: false
            });
        });
    }

    setActiveMarker(marker) {
        this.activeMarker = marker;
        this.initializeMarkersOverlay();
        this.showOverlays(OverlayType.markers);
    }
}

customElements.define('yeti-panorama-map', PanoramaMap);
