import {html, LitElement} from 'lit-element';
import styles from './startButton.css';

export class StartButton extends LitElement {
    static get styles() {
        return [styles];
    }

    static get properties() {
        return {
        }
    }

    render() {
        return html`<div class="start-button"><slot /></div>`
    }
}

customElements.define('yeti-start-button', StartButton);
