export const markers = [
    {
        type: 'camera',
        position: [13427, 14531],
        markdown: '/content/high-camp/high-camp.html'
    }, {
        type: 'camera',
        position: [16871, 14985],
        markdown: '/content/sunrise/sunrise.html'
    }, {
        type: 'camera',
        position: [24267, 11772],
        markdown: '/content/last-step/last-step.html'
    }, {
        type: 'camera',
        position: [18820, 15246],
        markdown: '/content/anstieg/anstieg.html'
    }, {
        type: 'camera',
        position: [9253, 14836],
        markdown: '/content/mera-la/mera-la.html'
    }, {
        type: 'camera',
        position: [10434, 14985],
        markdown: '/content/mera-la/mera-la2.html'
    }, {
        type: 'camera',
        position: [11780, 14551],
        markdown: '/content/glacier/glacier.html'
    }, {
        type: 'camera',
        position: [2722, 12544],
        markdown: '/content/peak/peak.html'
    }, {
        type: 'camera',
        position: [4794, 14175],
        markdown: '/content/basecamp/basecamp.html'
    }, {
        type: 'camera',
        position: [7973, 15186],
        markdown: '/content/teepause/teepause.html'
    }, {
        type: 'camera',
        position: [7531, 15068],
        markdown: '/content/grat/grat.html'
    }
];
