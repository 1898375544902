import {css} from 'lit-element';

export default css`.start-button {
    position: relative;
    display: inline-block;
    color: white;

    font-size: 1.25rem;
    line-height: 2.5rem;
    font-weight: 500;
    text-align: center;
    background-color: #3575c2;
    border: solid 2px white;
    border-radius: 4px;
    height: 2.5rem;
    min-width: 10rem;
    letter-spacing: 1px;
    transition: all 0.1s ease-in-out;
    cursor: pointer;
    user-select: none;
    text-transform: uppercase;
}

.start-button:hover {
    letter-spacing: 3px;
    background-color: #3e8de5;
    transition: all 0.1s ease-in-out;
}

@media (max-width: 450px) {
    :host {
        font-size: 1.5rem;
    }
}
`;
  